.application {
  margin:1rem 0;
  padding: 1rem;
  background-color: #333333;
  border-radius: .25rem;
}

.nameGrid {
  margin: auto;
  max-width: 20rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-items: center;
}

.name {
  font-size: 1.5rem;
  font-weight: 500;
}

.sport {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.label {
  font-weight: 500;
}

.text {
  margin: .25rem 1rem;
}

.buttons {
  margin: auto;
  max-width: 20rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-items: center;
}