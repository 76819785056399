.reviewVideo {
  margin: .5rem 0;
}


.mainGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.headerGrid {
  display: grid;
  grid-template-columns: 6rem 1fr;
  gap: 2rem;
  justify-items: left;
  align-items: center;
}


.recipient {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.requestDescription {
  text-align: left;
  margin-bottom: 1rem;
  line-height: 1.25rem;

}

.bottomContainer {
  max-width: 40rem;
  width: 100%;
  margin:auto;
  text-align: left;
}

.keyTakeawaysLabel {
  text-align: left;
  font-size: 1.25rem;
  font-weight: 600;
}

.keyTakeawaysDescription {
  text-align: left;
  margin-bottom: .5rem;
  line-height: 1.25rem;
}

.keyTakeaways {
  width: 100%;
  
}

.buttonGrid {
  display: grid;
  grid-template-columns: 1fr 1rem 3rem;
  margin: 1rem 0;
}

.modalTitleGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-content: center;
  margin-bottom: 1rem;
}