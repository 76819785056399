.stepsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.stepNumber{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem white solid;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}

.stepNumberSelected{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem solid #0DBFB3;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}