.sportFilter {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media (hover) {
  .sportFilter:hover {
    animation: shake 0.25s;
    animation-iteration-count: infinite;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  100% { transform: translate(1px, -2px) rotate(1deg); }
}