.button {
  background-color: #4A91B5;
  font-size: 1.25rem;
  color: white;
  padding: .25rem .5rem;
  border-radius: .5rem;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.accentButton {
  background-color: #f16c19;
  font-size: 1.25rem;
  color: white;
  padding: .25rem .5rem;
  border-radius: .5rem;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stretchButton {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #4A91B5;
  font-size: 1.25rem;
  color: white;
  padding: .25rem .5rem;
  font-weight: 400;
  border-radius: .5rem;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.accentStretchButton {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #f16c19;
  font-size: 1.25rem;
  color: white;
  padding: .25rem .25rem;
  font-weight: 400;
  border-radius: .5rem;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.tab {
  background-color: #2f4c7c;
  font-size: 1.25rem;
  color: #F7F7F2;
  padding: .5rem .75rem;
  font-weight: 500;
  border-radius: .25rem;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.activeTab {
  background-color: #7899D4;
  font-size: 1.25rem;
  color: #F7F7F2;
  padding: .5rem .75rem;
  font-weight: 500;
  border-radius: .25rem;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stretchButtonRed {
  background-color: #B20000;
  color: #e0e2e8;
  padding: .5rem 1rem;
  font-weight: bold;
  letter-spacing: .15rem;
  border-radius: .5rem;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
