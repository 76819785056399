.experienceContainer {
  margin: .25rem 0rem 0rem 0rem;
}

.buttonMargin {
  margin-top: .75rem
}

.experienceLabel {
  margin: 0rem 1rem;
  text-align: center;
}

.image {
  text-align: center;
  margin:auto;
  max-height: 9rem;
  max-width: 100%;
}

.dragZoneContainer {
  height: 9rem;
  background-color: #3b4066;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dropZoneLabel {
  text-align: center;
}