
.mainGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.topGrid {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
}

.labelFlex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.starGrid {
  display: grid;
  grid-template-columns: 2.5rem 2.5rem 2.5rem 2.5rem 2.5rem auto;
  align-items: center;
  margin: .25rem 0
}

.starContainer {
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}