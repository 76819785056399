.sideMenu {
  min-width: 280px;
  min-height: 100%;
  background: #191a1b;
  border-radius: 0rem .5rem 0rem 0rem;
  color: white;
  text-align: center;
}

.fullMenu {
  width: 100%;
  min-height: 100%;
  margin: 0 1rem;
  background: #191a1b;
  border-radius: 0rem 0rem 0rem 0rem;
  color: white;
  text-align: center;
  
}

.title {
  font-size: 2rem;
  font-weight: 700;  
  margin: 0rem 1rem .5rem 1rem;
  padding: 0 0rem 1rem 0;
  color: white;
}

.menuItem {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: .5rem;
  color: white;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menuItem:hover {
  color: #bebebe;
}

.selectedMenuItem {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: .5rem;
  color: white;
  cursor: pointer;
  border-right: solid .5rem white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selectedMenuItem:hover {
}

.closeButton {
  text-align: right;
  padding: .5rem .5rem 0 0;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dropDownMenu {
  position: absolute;
  right: 0rem;
  font-weight: 500;
  background-color: #81A4B5;
  padding: .5rem 1rem;
  border-radius: .75rem;
  color: #162B36;
  display: block;
  margin-top: .2rem;
  min-width: 8rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dropDownMenuItem {
  border-bottom: .1rem solid #162B36; 
}

.dropDownMenuItem:hover {
  color: white;
}

.lastDropDownMenuItem {
}

.lastDropDownMenuItem:hover {
  color: white;
}