.topGrid {
  display: grid;
  grid-template-columns: 1fr 2rem;
  justify-items: left;
  align-items: center;
  margin-bottom: .5rem;
}

.sportIconContainer {
  width: 2rem;
  height: 2rem;
}

.sportIcon {
  height: 100%;
  width: 100%;
}

.name {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
}

.price {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
}


.requestDate {
  font-size: .8rem;
  text-align: center;
  color: #cecece;
}

.imageContainer {
  margin: .5rem auto;
  width: 6rem;
}

.image {
  width: 100%;
  max-height: 100%;
  border-radius: .5rem;
}

.reviewerName {
  font-weight: bold;
  letter-spacing: .1rem;
  font-size: 1rem;
  width: 10rem;
  overflow: hidden;
  white-space: nowrap;
}

.status {
  background-color: #4EA699;
  padding: .5rem 0 .5rem 0;
  border-radius: 0rem 0rem .5rem .5rem;
  font-weight: 500;
  font-size: 1.25rem;
}

.expired {
  text-align: center;
  font-size: 1rem;
  color: #c03131;
}

.days {
  text-align: left;
  font-size: .8rem;
  color: rgb(3, 179, 3);
}

.hours {
  text-align: left;
  font-size: .8rem;
  color: yellow;
}