.container {
  padding:.25rem .5rem;
  margin: .5rem 0;
  background-color: #2B2C41;
  border-radius: .5rem;
  border-color: transparent;
}

.deleteButtonContainer {
  margin-bottom: .25rem;
}

.deleteButton {
  background-color: #E13E3E;
  color: #e0e2e8;
  padding: .5rem .75rem;
  font-weight: bold;
  letter-spacing: .15rem;
  border-radius: .5rem;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}