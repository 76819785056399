.textLabel {
  padding: .0rem 0rem;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: .1rem;
}

.textInput {
  width: 100%;
  border-radius: .25rem;
  border-color: transparent;
  border-width: 2px;
  padding: .2rem .2rem;
  height: 2rem;
}

.textAreaInput {
  width: 100%;
  border-radius: .25rem;
  border-color: transparent;
  border-width: 2px;
  padding: .2rem .2rem;
  height: 4rem;
  resize: none;
}

.multiSelectInput {
  width: 100%;
  border-radius: .25rem;
  border-color: transparent;
  border-width: 2px;
  padding: .2rem .2rem;
  height: 6rem;
}

.label {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 500;
  color:white;
}

.text {
  line-height: 1.2rem;
}

.subLabel {
  display: inline-block;
  font-size: .75rem;
  font-style: italic;
}

.pageLabel {
  font-size: 2rem;
  font-weight: 700;
  color: #4A91B5;
}

.error {
  font-size: 1.25rem;
  color: rgb(255, 0, 0);
}


.loaderContainer {
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  margin:auto;
}

.loaderText {
  text-align: center;
  margin-top: 1rem;
}

.loader {
  margin:auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #7899D4;
  border-right: 16px solid #4EA699;
  border-bottom: 16px solid #B20000;
  border-left: 16px solid white;
  width: 8rem;
  height: 8rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}