.footerContainer {
  margin: 3rem 0 0 0 ;
  padding: 1rem;
  background-color: #161616 ;
}

.sectionGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  gap:1rem;
}

.applicationFlexBox {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
}

.socialLink {
  width: 2rem;
  cursor: pointer;
}

