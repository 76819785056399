.loginContainer {
    padding-top: 10vh;
    padding-left: 25vw;
    padding-right: 25vw;
    text-align: left;
    min-height: 100%;
    flex-grow: 1;
}

.errorMessage {
    color:red;
}

.signupContainer {
    margin-top: 2rem;
    display: flex;
}

.signupLabel {
    margin-right: 2rem;
}