.experienceContainer {
  margin: .25rem 0rem 0rem 0rem;
}

.buttonMargin {
  margin-top: .75rem
}

.experienceLabel {
  margin: 0rem 1rem;
  text-align: center;
}

.image {
  text-align: center;
  margin:auto;
  max-height: 9rem;
  max-width: 100%;
}

.dragZoneContainer {
  height: 9rem;
  background-color: #2B5468;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dropZoneLabel {
  text-align: center;
}

.modal {
  display:inline-block;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  padding: 10px;
  margin:auto;
  border-radius: .5rem;
}

.overlay {
  position: fixed;
  top:0px;
  bottom: 0px;
  left:0px;
  right: 0px;
  display: grid;
}

.content {
  width: 300px;
  height: 300px;
  position: relative;
}

.stepsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-items: center;
  gap: 1rem;
  margin: .5rem 0;
}

.stepNumber{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem white solid;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}

.stepNumberSelected{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem solid #0DBFB3;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}
