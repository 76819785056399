/* Buttons */
.buttonPrimary {
  margin:auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  background-color: #147efb;
  font-size: 1.25rem;
  padding: .5rem .5rem;
  border: .15rem solid #147EFB;
  border-radius: .25rem;
  font-weight: 400;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.buttonPrimary:hover {
  background-color: #0e64c5;
}

/* Buttons */
.outlineButtonPrimary {
  margin:auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  border: .15rem solid #147EFB;
  font-size: 1.25rem;
  box-sizing: content-box;
  padding: .5rem .5rem;
  border-radius: .25rem;
  font-weight: 400;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.outlineButtonPrimary:hover {
  background-color: #147EFB;
}

.buttonSecondary {
  background-color: #ff14af;
  margin:auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  font-size: 1.25rem;
  padding: .5rem .5rem;
  border: .15rem solid #ff14af;
  border-radius: .25rem;
  font-weight: 400;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.buttonSecondary:hover {
  background-color: #bb0e82;
  border: .15rem solid #bb0e82;
}

.stretchButtonPrimary {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #147EFB;
  font-size: 1.25rem;
  padding: .5rem .5rem;
  font-weight: 400;
  border-radius: .25rem;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stretchButtonPrimary:hover {
  background-color: #0e64c5;
}

.stretchButtonSecondaryDisabled {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #42C7EB;
  font-size: 1.25rem;
  padding: .5rem .5rem;
  font-weight: 400;
  border-radius: .25rem;
  text-align: center;
  cursor: not-allowed;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

.stretchButtonSecondary {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #ff14af;
  font-size: 1.25rem;
  padding: .5rem .5rem;
  font-weight: 400;
  border-radius: .25rem;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stretchButtonSecondary:hover {
  background-color: #bb0e82;
}

.stretchButtonSecondaryDisabled {
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: #ff14af;
  font-size: 1.25rem;
  padding: .5rem .5rem;
  font-weight: 400;
  border-radius: .25rem;
  text-align: center;
  cursor: not-allowed;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

.iconButton {
  padding:.5rem;
  border-radius: .25rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

.iconButton:hover {
  background-color: black;
}

.linkButton {
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

.linkButton:hover {
  text-decoration: underline;
}

/* Containers */
.pageContainer {
  width: 100%;
  margin-bottom: 3rem;
}

.page {
  margin: 0 .5rem;
}

.pageContentContainer {
  max-width: 45rem;
  margin: 0 auto 1rem auto;
  padding: 0 1rem;
}

.contentContainer {
  background-color: #191a1b;
  border-radius: .5rem;
  padding: 1rem;
  margin: .5rem 0;
}

.textGroupContainer {
  margin-bottom: .75rem;
}

/* Labels */
.pageLabel {
  font-size: 3.5rem;
  font-family: "bebas_neueregular";
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.contentLabel {
  font-size: 1.75rem;
  font-family: "bebas_neueregular";
  color: #F2F2F2;
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.contentSubLabel {
  font-size: 1rem;
  font-weight: normal;
  color: #F2F2F2;
}

.label {
  font-size: 1.5rem;
  margin: .25rem 0;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.label_sm {
  font-size: 1.25rem;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.label_s {
  font-size: 1.25rem;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}


.label_m {
  font-size: 1.5rem;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.label_l {
  font-size: 1.75rem;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.label_xl {
  font-size: 2.55rem;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.label_xxl {
  font-size: 5.5rem;
  color: #F2F2F2;
  font-family: "bebas_neueregular";
  letter-spacing: .1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.labelDescription {
  font-size: .85rem;
  font-weight: normal;
  color: #d8d7d7;
  margin-bottom: .2rem;
}

.labelError {
  font-size: .85rem;
  font-weight: normal;
  color: #c24141;
}

/* Text */

.text_xs {
  font-size: .85rem;
  line-height: 1.30;
  color:#b4b3b3;
}

.text_s {
  font-size: 1rem;
  line-height: 1.15;
  color:#b4b3b3;
}

.text_m {
  font-size: 1.15rem;
  line-height: 1.3;
  color:#b4b3b3;
}

.text_l {
  font-size: 1.25rem;
  line-height: 1.4;
  color:#b4b3b3;
}

.contentText {
  font-size: 1rem;
  color: rgb(202, 202, 202);
  line-height: 1.4rem;
  margin: .25rem 0;
}

.subText {
  font-size: .85rem;
  font-weight: normal;
}

.featuredTextPrimary {
  font-size: 1.5rem;
  color: #147efb;
  line-height: 1.75rem;
  margin: .25rem 0;
  font-weight: 700;
  letter-spacing: .1rem;
}

.featuredTextSecondary {
  font-size: 1.5rem;
  color: #ff14af;
  line-height: 1.75rem;
  margin: .25rem 0;
  font-weight: 700;
}

/* Inputs */
.textInput {
  font-size: 1.35rem;
  width: 100%;
  padding:.5rem 1rem;
  margin: .25rem 0;
  border-radius: .25rem;
  border: solid .1rem #585858;
  color: #F2F2F2;
  background-color: #191a1b;
  box-sizing: border-box;
}

.textInputError {
  font-size: 1.35rem;
  width: 100%;
  padding:.5rem 1rem;
  margin: .25rem 0;
  border-radius: .25rem;
  color: #F2F2F2;
  background-color: #191a1b;
  border: solid .1rem red;
  box-sizing: border-box;
}

.textAreaInput {
  font-size: 1.15rem;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 8rem;
  padding:.5rem 1rem;
  margin: .25rem 0;
  border-radius: .25rem;
  color: #F2F2F2;
  border: solid .1rem #585858;
  background-color: #191a1b;
  box-sizing: border-box;
}

.textAreaInputError {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  margin: .25rem 0;
  padding:.5rem 1rem;
  border-radius: .25rem;
  border: solid .1rem red;
  color: #F2F2F2;
  background-color: #191a1b;
  box-sizing: border-box;
}

.checkbox {
  vertical-align: center;
}

.radioButton {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  font-size: 1rem;
  border: solid .15rem transparent;
  color: #F2F2F2;
  background-color: #191a1b;  
  padding: .5rem .5rem;
  border-radius: .25rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.radioButton:hover {
  background-color: #303030;
}

.radioButtonSelected {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  font-size: 1rem;
  border: solid .15rem #147efb;
  color: #F2F2F2;
  background-color: #191a1b;  
  padding: .5rem .5rem;
  border-radius: .25rem;
  font-weight: 500;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.radioButtonSelected:hover {
  background-color: black;
}

.colorButton {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  height: 1.25rem;
  border: solid .15rem transparent;
  color: #F2F2F2;
  background-color: #191a1b;  
  padding: .5rem .5rem;
  border-radius: .25rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.colorButton:hover {
 opacity: 60%;
}

.colorButtonSelected {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  font-size: 1rem;
  border: solid .15rem white;
  color: #F2F2F2;
  background-color: #191a1b;  
  padding: .5rem .5rem;
  border-radius: .25rem;
  font-weight: 500;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.colorButtonSelected:hover {
  opacity: 60%;
}

/* Layout */
.columnGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: .5rem;
}

.labelGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.5rem;
  align-items: center;

}

/* Links */
.textLink {
  font-size: 1.5rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.textLink:hover {
  text-decoration: underline;
}

.textLinkPrimary {
  color: #147efb;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.textLinkPrimary:hover {
  text-decoration: underline;
}


.textLinkSecondary {
  color: #FF14AF;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.textLinkSecondary:hover {
  text-decoration: underline;
}

/*  Process Steps */
.stepsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-items: center;
  gap: 1rem;
  margin: .5rem 0;
}

.stepNumber{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem white solid;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}

.stepNumberSelected{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem solid #147EFB;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}

/* Dropdown Menu */
.dropdownMenu {
  position:absolute;
  right: 0rem;
  background-color: #333439;
  padding: .5rem;
  border-radius: .25rem;
  color: white;
  display: block;
  margin-top: .2rem;
  width: 17rem;
  z-index: 999;
}

/* Loader */
.spinLoaderContainer {
  height: 100%;
  width: 100%;
  min-height: 25rem;
  display: grid;
  grid-template-rows: 1fr auto auto;
  justify-items: center;
  align-items: center;
}

.spinLoader {
  margin:auto;
  border: 16px solid #ff9819;
  border-radius: 50%;
  border-top: 16px solid #147efb;
  border-right: 16px solid #ff14af;
  border-bottom: 16px solid #11d465;
  border-left: 16px solid #b245e6;
  width: 8rem;
  height: 8rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Modal */
.modal {
  display:inline-block;
  border: 1px solid rgb(165, 165, 165);
  background: #333439;
  outline: none;
  padding: 1rem;
  margin:auto;
  max-height: 80vh;
  overflow:auto;
  width: 100%;
  max-width: 30rem;
  min-width: 18rem;
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  top:0px;
  bottom: 0px;
  left:0px;
  right: 0px;
  display: grid;
  background-color: #ffffff51;
}

.modalHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
}

/* Nav Menu */
.navMenu {
  background-color: black;
}

.navMenuItems {
  padding: .5rem 0rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 8rem);
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}

.navMenuItem {
  height: 6rem;
  width: 8rem;
  background-color: #191a1b;
  border-radius: .5rem;
  box-sizing: border-box;
  text-align: center;
  padding:1rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.navMenuItem:hover {
  background-color: #5e5e5e;
}

.navMenuItemSelected {
  height: 6rem;
  width: 8rem;
  background-color: #3c3e41;
  border-radius: .5rem;
  box-sizing: border-box;
  text-align: center;
  padding:1rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.navMenuItemSelected:hover {
  background-color: #5e5e5e;
}

.dropdownGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap:3rem;
  align-items: center;
  justify-items: left;
  padding: 1rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dropdownGrid:hover {
  background-color: black;
}

.breadcrumbs {
  display: flex;
  margin: 0rem 1rem;
  font-size: 1rem;
}

.switchEnabledLeft {
  padding: 1rem 1rem;
  border-radius: .25rem 0rem 0rem .25rem;
  background-color: #147efb;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.switchDisabledLeft {
  padding: 1rem 1rem;
  border-radius: .25rem 0rem 0rem .25rem;
  background-color: white;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.switchEnabledRight {
  padding: 1rem 1rem;
  border-radius: 0rem .25rem .25rem 0rem;
  background-color: gray;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.switchDisabledRight {
  padding: 1rem 1rem;
  border-radius: 0rem .25rem .25rem 0rem;
  background-color: white;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}