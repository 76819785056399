.container {
  margin: 0 .5rem 1rem .5rem;
  overflow: hidden;
}

.titleSection {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: .75rem;
  align-items: baseline;
  justify-items: left;
}

.featuringLabel {
  text-align: left;
  font-size: 1.5rem;
  font-weight:600;
  letter-spacing: 0rem;
  color: white;
}

.reviewersContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap:1rem
}

.reviewersContainerMobile {
  width: 100%;
  margin: 0 auto;
  justify-items: center;
}

.viewMore {
  color: #4A91B5;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.pageNav {
  width: 18rem;
  margin:.5rem auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: center;
  align-items: center;
}