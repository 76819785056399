/* Backgrounds */
.lightBackground {
  background-color:#f1f2f6
}

.darkBackground {
  background-color:#0c0c0c
}

.mediumBackground {
  background-color:#1a1a1a
}

/* Headers */
.h1SmallLight {
  font-size: 1.5rem;
  font-weight: 600;
  color:#EAEAEA;
}
.h1MediumLight {
  font-size: 1.75rem;
  font-weight: 600;
  color:#EAEAEA;
}
.h1LargeLight {
  font-size: 2rem;
  font-weight: 600;
  color:#EAEAEA;
}

.h1SmallDark {
  font-size: 1.5rem;
  font-weight: 600;
  color:#1C1C1C;
}
.h1MediumDark {
  font-size: 1.75rem;
  font-weight: 600;
  color:#1C1C1C;
}
.h1LargeDark {
  font-size: 2rem;
  font-weight: 600;
  color:#1C1C1C;
}

/* Text */
.textSmallLight {
  font-size: 1rem;
  line-height: 1.5rem;
  color:#d0d0d0;
}
.textMediumLight {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color:#d0d0d0;
}
.textLargeLight {
  font-size: 1.5rem;  
  line-height: 2rem;
  color:#d0d0d0;
}
.textSmallDark {
  font-size: 1rem;
  line-height: 1.5rem;
  color:#1C1C1C;
}
.textMediumDark {
  font-size: 1.25rem;
  line-height: 1.75rem;
  color:#1C1C1C;
}
.textLargeDark {
  font-size: 1.5rem;
  line-height: 2rem;
  color:#1C1C1C;
}

/* Text */
.buttonTextSmallLight {
  font-size: 1rem;
  color:#EAEAEA;
}
.buttonTextMediumLight {
  font-size: 1.25rem;
  color:#EAEAEA;
}
.buttonTextLargeLight {
  font-size: 1.5rem;  
  font-weight: 600;
  letter-spacing: .01rem;
  color:#EAEAEA;
}
.buttonTextSmallDark {
  font-size: 1rem;
  color:#1C1C1C;
}
.buttonTextMediumDark {
  font-size: 1.25rem;
  color:#1C1C1C;
}
.buttonTextLargeDark {
  font-size: 1.5rem;
  color:#1C1C1C;
}

/* Label */
.labelSmallLight {
  font-size: 1rem;
  color:#EAEAEA;
  font-weight: 600;
  letter-spacing: .05rem;
}
.labelMediumLight {
  font-size: 1.25rem;
  color:#EAEAEA;
  font-weight: 600;
  letter-spacing: .05rem;
}
.labelLargeLight {
  font-size: 1.5rem;
  color:#EAEAEA;
  font-weight: 600;
  letter-spacing: .05rem;
}
.labelSmallDark {
  font-size: 1rem; 
  font-weight: 600;
  letter-spacing: .05rem;
  color:#1C1C1C;
}
.labelMediumDark {
  font-size: 1.25rem; 
  font-weight: 600;
  letter-spacing: .05rem;
  color:#1C1C1C;
}
.labelLargeDark {
  font-size: 1.5rem; 
  font-weight: 600;
  letter-spacing: .05rem;
  color:#1C1C1C;
}

/* Text Link */
.textLink {
  font-size: 1rem;
  font-weight: 600;
  color: #147efb;
  cursor: pointer;
}

.textLink:hover {
  text-decoration: underline;
}

/* Button */
.buttonPrimary {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  font-size: 1.25rem;
  padding: .5rem 1rem;
  border-radius: .5rem;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.buttonPrimary:hover {
  background-color: #353434;
}


.buttonSecondary {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background-color: #4353FF;
  border-radius: .5rem;
  cursor: pointer;
  padding:.5rem 1rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.buttonSecondary:hover {
  background-color: #4353FF;
}

.buttonTertiary {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background-color: #147efb;
  font-size: 1.25rem;
  padding: .5rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.buttonTertiary:hover {
  background-color: #0f5cb5;
}

.buttonIcon {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  border-radius: 50%;
  cursor: pointer;
  padding:.75rem;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

/* Inputs */
.textInput {
  font-size: 1rem;
  margin:.5rem 0;
  padding:.5rem 1rem;
  width: 100%;
  border-radius: .5rem;
  border: solid .1rem #585858;
  color: #F2F2F2;
  background-color: #191a1b;
  box-sizing: border-box;
}

.textInputError {
  font-size: 1rem;
  margin:.5rem 0;
  padding: .5rem 1rem;
  width: 100%;
  border-radius: .5rem;
  border: solid .1rem red;
  color: #F2F2F2;
  background-color: #191a1b;
  box-sizing: border-box;
}

.textAreaInput {
  font-size: 1rem;
  margin:.5rem 0;
  padding:.5rem 1rem;
  width: 100%;
  border-radius: .5rem;
  height: 5rem;
  border: solid .1rem #585858;
  color: #F2F2F2;
  background-color: #191a1b;
  box-sizing: border-box;
}

.textAreaInputError {
  font-size: 1rem;
  margin:.5rem 0;
  padding: .5rem 1rem;
  width: 100%;
  border-radius: .5rem;
  height: 5rem;
  border: solid .1rem red;
  color: #F2F2F2;
  background-color: #191a1b;
  box-sizing: border-box;
}
