.homePage {
  
}

/* Page */

.jumbotronContainer {
  /* background: linear-gradient(to bottom right, black 55%, #161616 80%); */
  /* background-color: #161616; */
}

.jumbotron {
  max-width: 70rem;
  margin:auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  
  padding: 1rem;
}

.contentItem {
  text-align: left;
  margin:0rem auto 2rem auto;
}

.howitworks {
  margin:auto;
  max-width: 70rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr) );
  justify-items: center;
  gap:2rem;
}