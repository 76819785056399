.modal {
    display:inline-block;
    border-radius: .5rem;
    background: rgb(184, 184, 184);
    outline: none;
    padding: 20px;
    margin:auto;
    min-width: 25rem;
}

.overlay {
    position: fixed;
    top:0px;
    bottom: 0px;
    left:0px;
    right: 0px;
    display: grid;
    padding:1rem;
    background-color: #ffffff3b;
}