.smGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  overflow: auto;
  gap:.5rem
}

.mdGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  overflow: auto;
}

.lgGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);  
  justify-items: center;
  max-width: 55rem;
  margin: 0 auto;
}