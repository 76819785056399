.navMenu {
  position:absolute;
  right: 0rem;
  background-color: #333439;
  padding: .5rem;
  border-radius: .5rem;
  color: white;
  display: block;
  margin-top: .2rem;
  width: 17rem;
  z-index: 999;
}

.navItem {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: .05rem;
  margin:1rem .5rem;
}

.navItem:hover {
  color: gray;
}

.actionButtons {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: .05rem;
}

.actionButtons:hover {
  color:rgb(102, 102, 102);
}


.userName {
  font-size: 1.75rem;
}

.userSquare {
  width: 2rem;
  height: 2rem;
  border-radius: .5rem;
  background-color: blueviolet;
}