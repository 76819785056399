.requestsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 12rem);
  grid-gap: .5rem;
}

.noRequests {
  line-height: 1.25rem;
  margin: 1rem 0;
}

.pageNav {
  width: 18rem;
  margin:.5rem auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: center;
  align-items: center;
}