.header {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  top: 0;
  background-color: black;
  opacity: 1;
  z-index: 99999;
} 

.headerGrid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  padding: .5rem;
}

.logo {
  height: 4rem;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.logoImg {
  height: 100%;
}

.headerLinks {
  display:grid;
  grid-template-columns: auto auto auto;
  height: 100%;
  align-items: center;
}

.headerLinksUser {
  display:grid;
  grid-template-columns: auto auto auto auto;
  margin-left: auto;
  height: 100%;
  align-items: center;
}

.headerLinksReviewer {
  display:grid;
  grid-template-columns: auto auto auto ;
  margin-left: auto;
  height: 100%;
}

.headerLinksAdmin {
  display:grid;
  grid-template-columns: auto;
  margin-left: auto;
  height: 100%;
  gap: 1rem;
}

.headerLink {
  justify-self: center;
  font-size: 1.25rem;
  font-weight: 500;
  align-self: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.headerLink:hover {
  text-decoration: underline;
}

.buttonContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: .5rem;
}