/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 8, 2021 */



@font-face {
    font-family: 'bebas_neueregular';
    src: url('bebasneue-regular-webfont.woff2') format('woff2'),
         url('bebasneue-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticaneuethin';
    src: url('helveticaneue_thin-webfont.woff2') format('woff2'),
         url('helveticaneue_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}