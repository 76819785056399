.card {
  display: inline-block;
  background-color: #191a1b;
  border-radius: .5rem;
  width: 18rem;
  margin: 0rem 1rem 1rem 0rem;
}

.clickableCard {
  display: inline-block;
  background-color: #191a1b;
  border-radius: .5rem;
  width: 18rem;
  margin: 0rem 1rem 1rem 0rem;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.cardContent {
  padding: .5rem;
  word-wrap: break-word;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  flex-grow: 1;
  text-align: center;
}

.textGroup {
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-gap: .5rem;
  justify-items: left;
  align-items: center;
  margin: .25rem 0;
}

.text {
  text-align: left;
  font-size: .85rem;
  line-height: 1.125rem;
  color: #b6b6b6;
}

.label {
  font-size: 1rem;
  margin-bottom: .25rem;
  font-weight: 500;
}

.date {
  margin: .25rem 0 .25rem 0;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
}

.expiredDate {
  margin: 0rem 0 .25rem 0;
  font-size: 1rem;
  text-align: center;
  color: #c03131;
}

.daysDate {
  margin: 0rem 0 .25rem 0;
  font-size: 1rem;
  text-align: center;
  color: #82f082;
}

.hoursDate {
  margin: 0rem 0 .25rem 0;
  font-size: 1rem;
  text-align: center;
  color: yellow;
}

.statusContainer {
  display: grid;
  grid-template-columns: 1fr auto auto;
  justify-items: center;
  align-items: center;
}

.status {
  font-size: 1.5rem;
  text-align: center;
  color: #2995f2;
  font-family: "bebas_neueregular";
}


.buttonContainer {
  padding:.25rem .5rem;
  border-radius: .5rem;
}

.buttonContainer:hover {
  background-color: black;
}

.menuIcon:hover {
  background-color: gray;
}

.metricsGrid {
  margin: .5rem 0;
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  grid-gap: .5rem;
  justify-items: center;
  align-items: center;
}