.coachGrid {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: center; 
  align-items: center;
}

.coachImage {
  width: 12rem;
  border-radius: .5rem;
}

.dragZoneContainer {
  width: 100%;
  height: 5rem;
  justify-items: center;
  align-items: center;
  border: .15rem dashed gray;
  border-radius: .25rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.dropZoneLabel {
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.checkboxGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: .5rem;
  align-items: center;
  justify-items: left;
}

.stepSectionLabel {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.stepNumber {
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem #147EFB solid;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 500;
  font-size: 1.25rem;
}