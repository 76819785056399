.menu {
  position:absolute;
  right: .75rem;
  background-color: #001e1d;
  padding: .5rem 1rem;
  border-radius: .75rem;
  color: white;
  display: block;
  margin-top: .2rem;
  min-width: 8rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.userPortalLinks {
  margin-left: 1.5rem;
}

.menu {
  position: absolute;
  right: .75rem;
  font-weight: 500;
  background-color: #4A91B5;
  padding: .5rem 1rem;
  border-radius: .75rem;
  color: #162B36;
  display: block;
  margin-top: .2rem;
  min-width: 8rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menuItem {
  border-bottom: .1rem solid #162B36; 
}

.menuItem:hover {
  color: white;
}

.lastMenuItem {
}

.lastMenuItem:hover {
  color: white;
}