.pageContent {
  max-width: 40rem;
  margin: 1rem auto;
  border-radius: .5rem;
  padding: 1rem;
}


.cardGrid {
  display:grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  margin-top:1rem;
}

.topIconGrid {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 1rem;
  margin: 0rem 1rem;
}

.sportIcon {
  height: 4rem
}

.sportIconSmall {
  height: 3rem
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: #4A91B5;
  text-align: center;
}

.profileImageContainer {
  height: 10rem;
  text-align: center;
  margin: 0rem;
}

.nameGrid {
  display:grid;
  grid-template-columns: 1fr auto;
  grid-gap: .25rem;
  padding-bottom: 0rem;
  align-items: center;
  justify-items: left;
}

.statsGrid {
  margin-top: 1rem;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: left;
  align-items: center;
  grid-gap: .5rem;
}

.ratingGrid {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  justify-items: left;
  align-items: center;
}


.mobileRatingGrid {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  border-radius: .5rem;
}

.reviewerName {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
}

.ratingLabel {
}

.role {
  overflow: hidden;
}

.experience {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  font-size: 1rem;
  height: 3.33rem;
}

.experienceText {
  font-size: 1.1rem;
  letter-spacing: .1rem;
  color: #F2F2F2;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.title {
  text-align: left;
  font-weight: 400;
  line-height: 1.4rem;
  color: #e0e0e0;
  font-size: 1.25rem;
}

.titleMobile {
  text-align: left;
  font-weight: 400;
  line-height: 1.2rem;
  color: #b6b6b6;
  font-size: 1rem;
  margin-bottom: .5rem;
}

.reviewLabelGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}