.createVideo {
  height:100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.topRow {
  width: 100%;
  padding: .5rem 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: center;
}

.nameGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

/* top row */
.recipient {
  font-size: 1.5rem;
  font-weight: 600;
}

.recDuration {
  min-height: 2.5rem;
  font-size: 1.5rem;
  color: red;
}

.middleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;  
  margin: 0 auto;
  justify-content: center;
}

.leftColumn {
  width: 9rem;
  padding: 0 .5rem;
}

.rightColumn {
  width: 9rem;
  padding: 0 .5rem;
}

.bottomRow {
  text-align: center;
}

/* Scrubbers */
.scrubControls {
  margin: auto auto auto auto;
  display: flex;
}

.frameScrubControls {
  margin-top: .5rem;
  margin-left: 2.25rem;
}

.videoPlayer {
  background-color: green;
  height: 100%;
}

.videoWrapper {
  height: calc(100%-4rem);
  height: -moz-calc(100% - 4rem);
  height: -webkit-calc(100% - 4rem);
}

.previewWrapper {
  max-height: 100%;
  height: 100%
}

.preview {
  max-height: 100%;
  max-width: 100%;
}

.hiddenElements {
  display: none;
}

.playButton {
  width: 2rem;
  height: 1.5rem;
  margin-right: .5rem;
}

/* Color */
.colorLabel {
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.colors {
  display: grid;
  grid-template-columns: repeat(auto-fit, 2.5rem);
  grid-gap: .5rem;
  justify-items: center;
  justify-content: left;
}

/* Square Button */
.squareButton {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #1a1a1a;
  border: .25rem solid transparent;
  width: 2rem;
  height: 2rem;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selectedSquareButton {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #1a1a1a;
  border: .25rem solid #4353FF;
  width: 2rem;
  height: 2rem;
  color: white;
  font-weight: 700;
  font-size: 1rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.squareButton:hover {
  background-color: #303133;
}

.clearButton {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #1a1a1a;
  border: .25rem solid transparent;
  height: 2rem;
  color: white;
  font-size: 1rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.clearButton:hover {
  background-color: #303133;
}

/* Speed */
.speeds {
  display: grid;
  grid-template-columns: 4rem;
  grid-gap: .25rem;
  justify-items: center;
  justify-content: center;
}

.speedButton {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #191a1b;
  width: 4rem;
  height: 2rem;
  color: #F7F7F2;
  font-weight: 600;
  font-size: 1.15rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selectedSpeedButton {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #4A91B5;
  width: 4rem;
  height: 2rem;
  color: #F7F7F2;
  font-weight: 600;
  font-size: 1.15rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.speedButton:hover {
  background-color: #303133;
}

/* Record */
.recordButton {
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: red;
  height: 4rem;
  color: #F7F7F2;
  font-size: 1rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.recordButton:hover {
  background-color: rgb(148, 9, 9);
}

.stopButton {
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: blue;
  height: 4rem;
  color: #F7F7F2;
  font-size: 1rem;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stopButton:hover {
  background-color: rgb(37, 37, 187);
}

.loadingContainer {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 2rem;
  height: 2rem;
  margin:auto;
}

.loader {
  margin:auto;
  border: 16px solid #ff9819;
  border-radius: 50%;
  border-top: 16px solid #147efb;
  border-right: 16px solid #ff14af;
  border-bottom: 16px solid #11d465;
  border-left: 16px solid #b245e6;
  width: 4rem;
  height: 4rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}