.container {
  margin: .75rem 0;
  padding: 1rem;
  /* width: 18rem; */
  width: 100%;
  color: white;
  border-radius: 1rem;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #1a1a1a;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  /* background: linear-gradient(to right bottom, #ff14af 20%, #161616 45%); */
  /* background: linear-gradient(to right bottom, #333333 5%, #161616 40.5%); */
}

.container:hover {
  /* background: linear-gradient(to bottom, #ff14af 25%, #161616 55%); */
}

.cardGrid {
  display:grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  margin-top:.5rem;
}

.sportIcon {
  height: 2.25rem
}


.profileImageContainer {
  margin: .75rem 0;
  height: 10rem;
  text-align: center;
}

.nameGrid {
  display:grid;
  grid-template-columns: 1fr auto;
  grid-gap: .25rem;
  align-items: center;
  justify-items: left;
}

.statsGrid {
  margin: .25rem 0;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  grid-gap: .5rem;
  font-size: .75rem;
}

.ratingGrid {
  margin-top: .5rem;
  text-align: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  border-radius: .5rem;
}

.reviewerName {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
}

.ratingLabel {
  font-weight: 400;
}

.role {
  overflow: hidden;
}

.experience {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  height: 3.33rem;
  line-height: .9rem;
}

.experienceText {
  font-size: 1rem;
  letter-spacing: .1rem;
  color: #F2F2F2;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.title {
  text-align: center;
  font-weight: 400;
  color: #b6b6b6;
  font-size: 1rem;
}