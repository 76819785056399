.login{
    text-align: left;
    margin: 2rem 1rem;
}

.content {
    max-width: 25rem;
    margin: auto;
}

.header {
    font-size: 2rem;
    font-weight: 500;
}

.errorMessage {
    color: red;
}

.settingContainer {
    margin: 1rem 0rem;
}

.textInput {
    width: 100%;
    border-radius: .5rem;
    border-color: transparent;
}

.linkContainer {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: left;
    align-items: center;
    gap: 1rem;
  }
  


