.pageContainer {
  width: 100%;
  margin: 0 1rem;
}

.pageBubble {
  background-color: white;
  font: black;
  padding: 1rem;
  margin: .25rem;
}

.pageContent {
  max-width: 50rem;
  margin: auto;
  padding: 0 rem;
}

.pageHeader {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.pageHeaderContent {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.formContainer {
  margin: 1rem 0rem;
}

.horizontalFlex {
  display: flex;
  flex-wrap: wrap;
}

.verticalFlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexFill {
  flex-grow: 1;
}

.columnGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: .5rem;
  margin: .5rem 0rem;
}

.tabContainer {
  
}