
.mainGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.topGrid {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
}

.labelFlex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}