.notificationContainer {
  border-radius: .25rem;
  background-color: #191a1b;
  margin: .5rem 0;
  padding: 1rem;
  border: .15rem solid transparent;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.newNotificationContainer {
  border-radius: .25rem;
  background-color: #191a1b;
  margin: .5rem 0;
  padding: 1rem;
  border: .15rem solid #147EFB;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}