.searchPage {
  width: 100%;
  height: 100%;
}

.searchFilters {
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: auto;
  margin:0 auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.searchFilters::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.searchFiltersMobile {
  display: flex;
  flex-direction: row;
  justify-content: left;
  overflow: auto;
  padding: 1rem;
}

.searchFilterMobile {
  display: grid;
  justify-items: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.searchFilterMobileSelected {
  display: grid;
  justify-items: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.filterLabel {
  text-align: center;
}

.results {
  margin-top: 0rem;
}

.headlineImage {
  display: inline;
  height: 4rem;
  margin-bottom: .5rem;
}