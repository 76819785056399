.app {
  min-height: 100vh;
  background-color: #0c0c0c;
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
  background-color: black;
}

.page {
  flex: 1;
}