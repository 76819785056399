.headerGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.doubleColumn {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  align-items: center;
}

.featuredGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}