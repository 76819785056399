.topGrid {
  display: grid;
  grid-template-columns: 1fr 2rem;
  justify-items: left;
  align-items: center;
  margin-bottom: .5rem;
}

.statsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: left;
  align-items: center;
  gap:.5rem;
  margin: .5rem 0;
}

.statGrid {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: center;
  align-items: center;
}

.followupGrid {
  display: grid;
  grid-template-columns: 6rem 1fr;
  justify-items: left;
  align-items: center;
  gap:.5rem;
}

.followup {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .1rem;
}