.instructions {

}

.stepContainer {
  border: solid .25rem #4A91B5;
  border-radius: .5rem;
  padding: .5rem;
  margin: 1rem 0rem 1rem 0rem;
  background-color: #191a1b;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selectedStepContainer {
  border-radius: .5rem;
  padding: .5rem;
  margin: 1rem 0rem 1rem 0rem;
  background-color: #191a1b;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stepLabel {
  font-size: 1.25rem;
  font-weight: 700;
}

.stepInstructions {
  margin-left: 1.5rem;
  color: white;
}

.stepsComplete {
  color: lightgray;
  margin-top: .25rem;
  text-align: left;
  font-weight: 500;
}