.dropZoneLabel {
  width: 100%;
  height: 4rem;
  border: .15rem dashed #676767;
  border-radius: .25rem;
  margin: 1rem 0;
  display:grid;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

.imageArea {

}

.cropper {
  position: relative;
  height: 400px;
}

.buttonsContainer {
  margin-bottom: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}

.stepsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.stepNumber{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem white solid;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}

.stepNumberSelected{
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  border: .2rem solid #0DBFB3;
  width: 4rem;
  height: 4rem;
  margin:auto;
  font-weight: 500;
  font-size: 1.75rem;
}