.ratingContainer {
  margin: 0rem 0rem 1rem 0;
  background-color: #191a1b;
  border-radius: .25rem;
  padding: .5rem 1rem;
}

.title {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 .25rem 0;
}

.starGrid {
  display: grid;
  grid-template-columns: 1.75rem 1.75rem 1.75rem 1.75rem 1.75rem auto;
  align-items: center;
  margin: .25rem 0
}