.container {
  margin: 1rem 0;
  padding: 1rem;
  width: 40rem;
  color: white;
  border-radius: 1rem;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
  background: linear-gradient(to right bottom, #333333 5%, #161616 60.5%);
  /* background: linear-gradient(to right bottom, #ff14af 10%, #161616 42.5%); */
}

.container:hover {
  background: linear-gradient(to right bottom, #ff14af 10%, #161616 42.5%);
}

.cardGrid {
  display:grid;
  grid-template-columns: 14rem 1fr;
  grid-gap: 1rem;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: #4A91B5;
  text-align: center;
}

.profileImageContainer {
  text-align: center;
}

.nameGrid {
  display:grid;
  grid-template-columns: 1fr auto;
  grid-gap: .25rem;
  padding-bottom: .25rem;
  align-items: center;
  justify-items: left;
}

.statsGrid {
  margin-top: 1rem;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: left;
  align-items: center;
  grid-gap: .5rem;
}

.ratingGrid {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  justify-items: left;
  align-items: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 1rem;
}

.reviewerName {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
}

.ratingLabel {
  font-size: 1.25rem;
}

.role {
  overflow: hidden;
}

.experience {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  font-size: 1rem;
  height: 3.33rem;
}

.experienceText {
  font-size: 1.1rem;
  letter-spacing: .1rem;
  color: #F2F2F2;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.title {
  text-align: left;
  font-weight: 400;
  line-height: 1.2rem;
  color: #e0e0e0;
  font-size: 1.25rem;
}