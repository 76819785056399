.pageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.columns {
  flex-grow: 1;
  display: flex;  
}

.content {
  flex-grow: 1;
  padding: 0 1rem 0 1rem;
  overflow: hidden;
}
