.userGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 10rem);
  gap: 2rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-top: 2rem;
}

.userCard {
  width: 100%;
}

.nameCard {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.addCard {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  background-color: black;
  border: .15rem solid white;
  border-radius: .25rem;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nameCard:hover {
  opacity: 60%;
}

.addCard:hover {
  background-color: #161616;
}

.nameCardBlue {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #147efb;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nameCardBlue:hover {
  background-color: #1261bb;
}

.nameCardPink {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #ff14af;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nameCardPink:hover {
  background-color: #bb0f82;
}

.nameCardGreen {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #11d465;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nameCardGreen:hover {
  background-color: #0c9d4b;
}

.nameCardPurple {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #b245e6;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nameCardPurple:hover {
  background-color: #8030a5;
}

.nameCardOrange {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #ff9819;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  border-radius: .25rem;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.nameCardOrange:hover {
  background-color: #c47311;
}

.cardIconGrid {
  display: grid;
  grid-template-columns: 1fr auto auto;
}

.nameGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: center;
  align-items: center;
}

.modalTitleGrid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-content: center;
  margin-bottom: 1rem;
}

.genderContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: .25rem;
}

.colorContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: .25rem;
}

.sportsGrid{
  display:grid;
  gap: .25rem;
}

.autologinGrid {
  max-width: 25rem;
  margin: auto;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 0rem;
}

.autologinCheckbox {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}