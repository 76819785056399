.toggleButtons {
  display: flex;
}

.toggleButton {
  display: grid;
  align-items: center;
  text-align: center;
  margin: 1rem;
  padding: 2rem;
  width: 6rem;
  background-color: black;
  border: #147efb .15rem solid;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.toggleButton:hover {
  background: #161616;
}

.toggleButtonSelected {
  display: grid;
  align-items: center;
  text-align: center;
  margin: 1rem;
  padding: 2rem;
  width: 6rem;
  background-color: #147efb;
  border: #147efb .15rem solid;
  cursor: pointer;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}

.toggleButtonSelected:hover {
}


.toggleButtonDisabled {
  display: grid;
  align-items: center;
  text-align: center;
  margin: 1rem;
  padding: 2rem;
  width: 6rem;
  background-color: #161616;
  border: #147efb .15rem solid;
  cursor: not-allowed;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none; 
}